

































































































































































import { Component } from 'vue-property-decorator';
import { loadView, loadWidget } from '@/utils/helpers';
import { Getter, namespace } from 'vuex-class';
import { IPayments, ISideBar, IUser } from '@/types/types';
import { links } from '@/data/sidebar';
import { mixins } from 'vue-class-component';
import WidgetMixins from '@/mixins/WidgetMixins';
import DialogMixins from '@/mixins/DialogMixins';
import Pusher from 'pusher-js';
import moment from 'moment';

const authModule = namespace('auth');
@Component({
  components: {
    'app--bar': loadView(`NavBar`),
    'app--drawer': loadView(`SideBar`),
    SideBarDrawer: loadView('SideBarDrawer'),
    TopUpDialog: loadView('pages/payments/dialogs/TopUpDialog'),
    HelpDialog: loadView('pages/help-center/HelpDialog'),
    snackBar: loadWidget('widgets/SnackBar'),
  },
})
export default class Master extends mixins(DialogMixins, WidgetMixins) {
  @Getter('getNavigationDrawerState') drawerState!: boolean;
  @authModule.Getter('getUserType') userType!: string;
  @authModule.Getter('getUserDetails') user!: IUser;
  state = true;
  links!: Array<ISideBar | { label: string }>;
  menu = false;

  emitDrawer(value: boolean): void {
    this.state = value;
  }

  closeDrawer(state: boolean): void {
    this.$store.dispatch('navigationDrawer', state, { root: true });
  }
  signOut(): void {
    this.$store.dispatch('auth/logOut', null);
  }
  sidebarLinks(key: string): Array<ISideBar | { label: string }> {
    return links[key];
  }

  initiatePayment(payload: IPayments): void {
    this.$store.dispatch('payments/initiatePayment', {
      ...payload,
      amount: Number(payload.amount),
    });
  }

  created(): void {
    this.links = this.sidebarLinks(this.userType);
    this.$store.dispatch('auth/me');
    this.$store.dispatch('checkCreditBalance');
    const pusher = new Pusher('094f3c8c8465aababfe0', {
      cluster: 'ap1',
    });

    const channel = pusher.subscribe('kairos-dashboard');
    channel.bind('sms-credit-event', (data: { totalCreditAmount: number }) => {
      const temp = Number(data.totalCreditAmount);
      this.$store.dispatch('updateCreditBalance', temp, { root: true });
    });

    channel.bind(
      'bulk-contact-upload',
      (data: { isCompleted: boolean; isSuccess: boolean; error?: string }) => {
        if (!data.isSuccess && data.error) {
          this.$store.dispatch(
            'snackBarMessage',
            data.error ?? 'Bulk contacts upload failed due to an error',
            { root: true }
          );
          this.$store.dispatch('snackBarVisibility', true, { root: true });
        } else {
          this.$store.dispatch('contact/list', {
            page: 1,
            limit: 30,
          });
        }
        this.$store.dispatch('inProgress', !data.isCompleted, { root: true });
        this.$store.dispatch('isLoading', false, { root: true });
      }
    );

    channel.bind(
      'bulk-logs-download',
      (data: {
        isCompleted: boolean;
        isSuccess: boolean;
        message: string;
        accountId: number;
      }) => {
        this.$store.dispatch('totalBulkRecords', 0, { root: true });
        this.$store.dispatch('isDownloading', false, { root: true });
        this.$store.dispatch('snackBarMessage', data?.message, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
        this.$store.commit('UPDATE_TIMEOUT', 10000, { root: true });
        if (this.$route.name === 'account.downloads') {
          this.$store.dispatch('log/listAllFileDownloads', {
            page: 1,
            limit: 15,
            query: `&date=${moment()
              .subtract(1, 'month')
              .format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`,
            subAccountId: data.accountId,
          });
        }
      }
    );
  }
}
