var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-column mid-container-full"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle,"balanceLoading":_vm.isBalanceLoading,"creditBalance":_vm.creditBalance},on:{"topUpDialog":function($event){return _vm.open('payment')}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"loading":_vm.pageLoading || _vm.inProgress,"small":"","color":"accent","icon":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('group/list', {
                      page: _vm.paginate.page,
                      limit: _vm.paginate.limit,
                    })}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])])]},proxy:true}])})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('div',{staticClass:"container d-flex flex-column flex-grow-1 flex-shrink-1 mt-5 animate__animated animate__fadeIn",staticStyle:{"padding":"0 !important"}},[_c('h2',{staticClass:"ft font-weight-medium pa-1"},[_vm._v("Groups Management")]),_c('p',{staticClass:"ft font-weight-medium px-2"},[_vm._v(" Make it easier for you to send tailored messages to certain numbers by categorizing them into groups. Upload contacts directly to a particular group ")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm mr-3 black--text",staticStyle:{"max-width":"500px"},attrs:{"filled":"","hide-details":"","rounded":"","loading":_vm.isLoading,"dense":"","prepend-inner-icon":"search","placeholder":"Search through groups ..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.groups.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 mt-5"},[_c('div',{staticClass:"d-flex flex-grow-1 justify-end"},[_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('add')}}},[_c('span',{staticClass:"ft text-capitalize font-weight-medium font-size-sm"},[_vm._v("Add Group")])])],1),_c('v-data-table',{staticClass:"ft font-weight-medium vx-shadow font-size-md ma-1",attrs:{"items":_vm.groups,"loading":_vm.pageLoading,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.paginate.total,"footer-props":{
                      itemsPerPageOptions: [30, 60, 90],
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
                    var item = ref.item;
return [_c('v-avatar',{staticClass:"white--text font-weight-bold font-size-md ma-1",attrs:{"color":"primary","size":"40"}},[_vm._v(_vm._s(item.name.charAt(0)))])]}},{key:"item.description",fn:function(ref){
                    var item = ref.item;
return [(item.description)?_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.description && item.description.length > 50 ? item.description.slice(0, 50) + '...' : item.description))]):_c('span',[_vm._v("No description available")])]}},{key:"item.groupContactsId",fn:function(ref){
                    var item = ref.item;
return [(
                          item.groupContactsId &&
                          item.groupContactsId.length > 0
                        )?_c('div',[_vm._l((item.groupContactsId.slice(0, 4)),function(group){return [_c('v-tooltip',{key:group.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"white--text ml-n2 vx-shadow-dim absolute",staticStyle:{"position":"relative","border":"2px solid white !important"},attrs:{"size":"35","color":"grey"}},on),[_vm._v(_vm._s(group.contactId.name.charAt(0)))])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(group.contactId.name)+" - "+_vm._s(group.contactId.phone))])])]}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [(item.groupContactsId.length > 4)?_c('span',_vm._g({staticClass:"ft font-weight-medium grey--text text--darken-1 mt-2 pl-1"},on),[_vm._v("+"+_vm._s(item.groupContactsId.slice(4) && item.groupContactsId.slice(4).length)+" more")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.groupContactsId .slice(4) .map(function (group) { return group.contactId.name; }) .join(',')))])])],2):_c('div',[_c('v-chip',{staticClass:"ft mx-1 font-weight-medium text-sm pa-2",attrs:{"color":"accent","small":"","label":""}},[_vm._v(" No contacts ")])],1)]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('details', item.id, 'group/details')}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("sms")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Send SMS")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openContactDialog(item)}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("person_add_alt")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Add Contact To Group")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('upload', item.id, 'group/details')}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("cloud_upload")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Upload Contacts")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('edit', item.id, 'group/details')}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("edit")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Edit Group")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open('delete', item.id, 'group/details')}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Delete Group")])])]}}],null,false,2557416639)})],1):_c('EmptyPage',{staticClass:"animate__animated animate__fadeIn mt-2",attrs:{"image":require('@/assets/images/contacts.svg'),"imageWidth":'125px',"title":_vm.search !== ''
                      ? ("No groups found for the search term " + _vm.search)
                      : "No Groups have been generated yet.","subTitle":_vm.search !== ''
                      ? "All searched groups results will show up here !"
                      : "All added groups will show up here !"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mt-5 mx-1",attrs:{"loading":_vm.isLoading,"disabled":_vm.isLoading,"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.open('add')}}},[_c('span',{staticClass:"ft font-weight-medium text-capitalize text-sm"},[_vm._v("Add Group")])])]},proxy:true}],null,false,2935905636)})],1)])]):_c('InternetConnection',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mt-8 ft font-weight-bold text-sm text-capitalize",attrs:{"color":"accent","loading":_vm.pageLoading,"rounded":""},on:{"click":function($event){return _vm.$store.dispatch('group/list', {
                    page: _vm.paginate.page,
                    limit: _vm.paginate.limit,
                  })}}},[_c('i',{staticClass:"material-icons-outlined mr-2"},[_vm._v("refresh")]),_vm._v(" Try Again ")])]},proxy:true}])})],1)],1)],1)]),_c('AddGroupDialog',{attrs:{"state":_vm.dialog('add'),"loading":_vm.isLoading},on:{"actions":_vm.close,"group":_vm.addGroup}}),_c('EditGroupDialog',{attrs:{"state":_vm.dialog('edit'),"dialogLoading":_vm.isDialogLoading},on:{"actions":_vm.close,"group":_vm.editGroup}}),_c('DeleteDialog',{attrs:{"state":_vm.dialog('delete'),"message":'Once you delete this contact, this operation cannot be undone.',"loading":_vm.isLoading},on:{"actions":_vm.close,"delete":_vm.deleteGroup}}),_c('AddContactToGroupDialog',{attrs:{"state":_vm.dialog('contacts'),"dialogLoading":_vm.isDialogLoading,"assignedContacts":_vm.assignedContacts,"loading":_vm.isLoading,"contacts":_vm.contacts},on:{"addContacts":_vm.addContactsToGroup,"actions":_vm.close}}),_c('SendSMSDialog',{attrs:{"state":_vm.dialog('details'),"credit-balance":_vm.creditBalance,"loading":_vm.isLoading},on:{"actions":_vm.close,"sendSMS":_vm.sendSMS}}),_c('AddSMSSenderID',{attrs:{"state":_vm.dialog('senderId'),"loading":_vm.isLoading},on:{"actions":_vm.close}}),_c('BulkUploadDialog',{attrs:{"state":_vm.dialog('upload'),"loading":_vm.isLoading},on:{"actions":_vm.close,"uploadFile":_vm.bulkUpload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }