var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"},attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-column mid-container-full"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle,"balanceLoading":_vm.isBalanceLoading,"creditBalance":_vm.creditBalance},on:{"topUpDialog":function($event){return _vm.open('payment')}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"loading":_vm.pageLoading,"small":"","color":"accent","icon":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('log/listAllFileDownloads', {
                      page: 1,
                      limit: 15,
                      query: _vm.dateFilter,
                      subAccountId: _vm.$route.params.id,
                    })}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])])]},proxy:true}])})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('div',{staticClass:"container-fluid d-flex flex-column flex-grow-1 flex-shrink-1 mt-5 animate__animated animate__fadeIn",staticStyle:{"padding":"0px !important"}},[_c('div',{staticClass:"d-flex animate__animated animate__fadeIn ml-5 flex-column flex-grow-1 flex-wrap flex-shrink-1"},[(_vm.$route.params.id)?_c('v-breadcrumbs',{staticClass:"pl-2 primary--text pb-0",attrs:{"items":_vm.breadcrumbs.concat( [{ text: _vm.customerDetails.name, disabled: true }] )},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"href":item.href,"disabled":item.disabled}},[_c('span',{staticClass:"ft font-weight-medium text-md"},[_vm._v(_vm._s(item.text))])])]}}],null,false,2661474978)}):_vm._e(),_c('h3',{staticClass:"ft font-weight-medium px-2 pt-6"},[_vm._v(" All Downloaded Files ")]),_c('p',{staticClass:"ft font-weight-medium px-2 pt-1"},[_vm._v(" All requested file downloads will show up here. These includes logs etc ")])],1),_c('div',{staticClass:"d-flex flex-row flex-wrap-reverse flex-sm-wrap flex-grow-1 mx-8 mt-2"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 mr-2"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},[_c('v-text-field',{staticClass:"vx-shadow mr-2 ft font-weight-medium text-sm",staticStyle:{"max-width":"500px"},attrs:{"hide-details":"","height":"39px","prepend-inner-icon":"search","placeholder":"Search by file name ...","solo":"","clearable":"","flat":"","rounded":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),(_vm.subAccounts && _vm.subAccounts.length > 0)?_c('v-combobox',{staticClass:"vx-shadow ft font-weight-medium text-sm rounded-lg hidden-sm-only",staticStyle:{"max-width":"250px"},attrs:{"solo":"","placeholder":"Filter By Sub Accounts","hide-details":"","items":_vm.subAccounts,"item-text":"name","item-value":"id","clearable":"","flat":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var attrs = ref.attrs;
                    var item = ref.item;
                    var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"label":"","input-value":selected,"small":"","color":"primary"}},'v-chip',attrs,false),[_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v(_vm._s(item.name))])]):_vm._e()]}},{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium text-md px-2"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,285276114),model:{value:(_vm.subAccountId),callback:function ($$v) {_vm.subAccountId=$$v},expression:"subAccountId"}}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row"}),_c('DoubleDatePicker',{attrs:{"loading":_vm.pageLoading},on:{"refresh":_vm.filterLogsByDate}})],1),(_vm.logs.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 mx-6"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 vx-shadow white mt-2 mb-5 py-0 rounded-md"},[_c('v-data-table',{staticClass:"ft font-weight-medium font-size-md mx-1 pb-2",attrs:{"headers":_vm.headers,"fixed-headers":"","items":_vm.logs,"loading":_vm.pageLoading,"item-key":"id","options":_vm.options,"server-items-length":_vm.paginate.total,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.updatedAt))])]}},{key:"item.type",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticClass:"text-uppercase",attrs:{"label":"","color":"primary","small":""}},[_vm._v(_vm._s(item.type))])]}},{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"ft text--underline cursor-pointer font-weight-medium text-sm",attrs:{"x-small":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportFile(item)}}},[_vm._v(_vm._s(item.name)+"."+_vm._s(item.extension)),_c('i',{staticClass:"material-icons-outlined text-md pl-2"},[_vm._v("launch")])])]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [(_vm.statusColors && _vm.statusColors[item.status])?_c('v-chip',{staticClass:"mr-3",attrs:{"small":"","color":_vm.statusColors[item.status].bg,"label":""}},[_c('span',{staticClass:"ft text-uppercase font-weight-bold",style:({ color: _vm.statusColors[item.status].text })},[_vm._v(" "+_vm._s(item.status))])]):_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v("Loading ...")])]}},{key:"item.fileSize",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{staticClass:"mr-3",attrs:{"small":"","color":"primary","label":""}},[_c('span',{staticClass:"ft text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(item.fileSize))])])]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [(item.status === 'IN-PROGRESS')?_c('img',{staticClass:"ml-2 mt-1",staticStyle:{"width":"24px"},attrs:{"src":require("@/assets/images/icons8-download-from-cloud.gif")}}):[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","loading":_vm.selectedFileId === item._id
                                    ? _vm.isLoading
                                    : false,"disabled":item.status === 'FAILED',"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportFile(item)}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v(_vm._s('file_download'))])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Download File")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmFileDelete(item._id)}}},on),[_c('i',{staticClass:"material-icons-outlined text-md"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Delete File")])])]]}}],null,false,3064439303)}),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mb-5"},[_c('v-pagination',{staticClass:"ft font-weight-medium text-md",attrs:{"circle":"","total-visible":7,"color":"primary","length":_vm.paginate.pages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]):_c('EmptyPage',{staticStyle:{"min-height":"400px"},attrs:{"title":"No logs available","imageWidth":'100px',"subTitle":"All sms reports will show up here","image":require('@/assets/images/folder.svg')}})],1)])]):_vm._e()])],1)],1)]),_c('DeleteDialog',{attrs:{"state":_vm.dialog('delete'),"message":'Once you delete this file, this operation cannot be undone.',"loading":_vm.isLoading},on:{"actions":_vm.close,"delete":_vm.deleteFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }